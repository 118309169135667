html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 80px; /* Margin bottom by footer height */
}

.a-atom-logo {
  min-width: 40px;
  max-width: 100%;
  margin: auto;
  display: block;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px; /* Set the fixed height of the footer here */
  line-height: 80px; /* Vertically center the text there */
  background-color: #ffffff;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}